<template>
  <div class="table-data-kpi">
    <!--This is old code to white space under filter-->
    <v-row justify="space-between" align="center" class="top"> </v-row>
    <v-row align="end"> </v-row>
    <!-- End of old code-->
    <v-row class="justify-end">
      <v-btn
        class="px-10 mb-5"
        elevation="0"
        height="36"
        color="primary"
        @click="downloadExcel"
        :disabled="isLoading"
      >
        Download file excel
      </v-btn>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="filtered"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      hide-default-header
      hide-default-footer
      @page-count="pageCount = $event"
      class="elevation-0 kpi-table"
      id="exported-kpi-table"
      fixed-header
      height="70vh"
    >
      <template v-slot:header="{}">
        <thead style="border-radius: 20px; text-align: center" class="v-data-table-header">
          <tr class="kpi-table-header">
            <th
              v-for="(header, index) in headers"
              :key="index"
              style="
                background: #0b2585;
                color: #fff;
                text-align: center;
                border: 1px solid #dae3e8;
              "
              :colspan="header.child.length === 0 ? 1 : header.child.length"
              :rowspan="header.child.length === 0 ? 2 : 1"
              v-html="header.text"
            ></th>
          </tr>
          <tr>
            <template v-for="(header, index) in headers">
              <th
                :key="index + headerChild"
                v-for="headerChild in header.child"
                style="height: 48px !important; text-align: center; border: 1px solid #dae3e8"
                v-html="headerChild"
              ></th>
            </template>
          </tr>
        </thead>
      </template>
      <template v-slot:body="{ items }">
        <tbody v-if="isLoading">
          <tr>
            <td :colspan="colNum">
              <h3 class="text-center mt-5 mb-2">Getting your data...</h3>
              <v-progress-linear
                indeterminate
                rounded
                color="primary"
                class="mb-5"
              ></v-progress-linear>
            </td>
          </tr>
        </tbody>
        <tbody v-else-if="items.length === 0">
          <th :colspan="colNum"><p class="py-2">There is no data</p></th>
        </tbody>
        <tbody v-else>
          <tr v-for="(item, index) in items" :key="index">
            <td style="min-width: 200px">{{ item.location }}</td>
            <td>{{ item.numOfStuTarget.toLocaleString() }}</td>
            <td>{{ item.numOfStuValue.toLocaleString() }}</td>
            <td>{{ item.numOfStuPct.toFixed(1) }}</td>
            <td>{{ item.avgStuTarget.toLocaleString() }}</td>
            <td>{{ item.avgStuActual.toLocaleString() }}</td>
            <td>{{ item.avgStuPct.toFixed(1) }}</td>
            <td>{{ item.revenueTarget.toLocaleString() }}</td>
            <td>{{ item.revenueActual.toLocaleString() }}</td>
            <td>{{ item.revenuePct.toFixed(1) }}</td>
            <td>{{ item.newSalesValueTarget.toLocaleString() }}</td>
            <td>{{ item.newSalesValueActual.toLocaleString() }}</td>
            <td>{{ item.newSalesValuePct.toFixed(1) }}</td>
            <td>{{ item.newSalesNumberTarget.toLocaleString() }}</td>
            <td>{{ item.newSalesNumberActual.toLocaleString() }}</td>
            <td>{{ item.newSalesNumberPct.toFixed(1) }}</td>
            <td style="min-width: 80px">{{ ((item.newSalesNumberPct + item.newSalesValuePct) / 2).toFixed(1) }}</td>
            <td style="min-width: 80px">{{ item.stopStuActual.toLocaleString() }}</td>
            <td>{{ item.nbrStu.toLocaleString() }}</td>
            <td>{{ item.stopStuPct.toFixed(1) }}</td>
          </tr>
          <tr style="background-color: #e0e0e0">
            <td style="background-color: #e0e0e0">Total</td>
            <td v-for="(value, key) in totalData" :key="key">{{ value }}</td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { kpi_report } from '@/api/kpi_report.js'
import XLSX from 'xlsx-js-style'
import dayjs from 'dayjs'

export default {
  props: {
    date: {
      type: String,
    },
  },
  data() {
    return {
      isLoading: false,
      page: 1,
      location: '',
      pageCount: 0,
      itemsPerPage: 100,
      listLocationSelect: [],
      headers: [
        { text: 'Center', child: [] },
        { text: 'Number of students', child: ['Target', 'Actual', '%'] },
        { text: 'Average Student', child: ['Target', 'Actual', '%'] },
        { text: 'Revenue', child: ['Target', 'Actual', '%'] },
        { text: 'Sale Value', child: ['Target', 'Actual', '%'] },
        { text: 'Sale Number', child: ['Target', 'Actual', '%'] },
        { text: 'Sale Result', child: [] },
        { text: 'Stopped Student', child: ['Stopped Students', 'Total Students', '%'] },
      ],
      listRender: [],
      filters: {},
    }
  },
  watch: {
    listLocation: function (newListLocation) {
      if (newListLocation.length !== 0) {
        this.location = newListLocation[0].locationID
        this.listLocationSelect = newListLocation.map(item => {
          return item.location
        })
      }
    },
    date: function () {
      this.init()
    },
    location: function () {},
  },
  created() {
    this.init()
  },
  computed: {
    filtered() {
      const listReport = this.listRender.filter(d => {
        return Object.keys(this.filters).every(f => {
          return this.filters[f].length < 1 || this.filters[f].includes(d[f])
        })
      })

      return listReport
    },
    colNum() {
      const childHeaderLength = this.headers.reduce((acc, curr) => {
        return acc + curr.child.length
      }, 0)

      return childHeaderLength + 1
    },
    headerText() {
      const result = []
      this.headers.forEach(header => {
        // Extract text property from the current object
        result.push(header.text)

        // If child property is not empty, extract its elements
        if (header.child.length > 0) {
          result.push(...header.child)
        }
      })
      return result
    },
    totalData() {
      let totalNumOfStuTarget = 0
      let totalNumOfStuActual = 0
      let totalRevenueTarget = 0
      let totalRevenueActual = 0
      let totalNewSalesValueTarget = 0
      let totalNewSalesValueActual = 0
      let totalNewSalesNumberTarget = 0
      let totalNewSalesNumberActual = 0
      let totalAvgStuTarget = 0
      let totalAvgStuActual = 0
      let totalStopStuActual = 0
      let totalNbrStu = 0

      this.listRender.forEach(el => {
        totalNumOfStuTarget += el.numOfStuTarget
        totalNumOfStuActual += el.numOfStuValue
        totalRevenueTarget += el.revenueTarget
        totalRevenueActual += el.revenueActual
        totalNewSalesValueTarget += el.newSalesValueTarget
        totalNewSalesValueActual += el.newSalesValueActual
        totalNewSalesNumberTarget += el.newSalesNumberTarget
        totalNewSalesNumberActual += el.newSalesNumberActual
        totalAvgStuTarget += el.avgStuTarget
        totalAvgStuActual += el.avgStuActual
        totalStopStuActual += el.stopStuActual
        totalNbrStu += el.nbrStu
      })

      return {
        numOfStuTarget: totalNumOfStuTarget.toLocaleString(),
        numOfStuActual: totalNumOfStuActual.toLocaleString(),
        numOfStuPct: ((totalNumOfStuActual / totalNumOfStuTarget) * 100).toFixed(1),
        avgStuTarget: totalAvgStuTarget.toLocaleString(),
        avgStuActual: totalAvgStuActual.toLocaleString(),
        avgStuPct: ((totalAvgStuActual / totalAvgStuTarget) * 100).toFixed(1),
        revenueTarget: totalRevenueTarget.toLocaleString(),
        revenueActual: totalRevenueActual.toLocaleString(),
        revenuePct: ((totalRevenueActual / totalRevenueTarget) * 100).toFixed(1),
        newSalesValueTarget: totalNewSalesValueTarget.toLocaleString(),
        newSalesValueActual: totalNewSalesValueActual.toLocaleString(),
        newSalesValuePct: ((totalNewSalesValueActual / totalNewSalesValueTarget) * 100).toFixed(1),
        newSalesNumberTarget: totalNewSalesNumberTarget.toLocaleString(),
        newSalesNumberActual: totalNewSalesNumberActual.toLocaleString(),
        newSalesNumberPct: ((totalNewSalesNumberActual / totalNewSalesNumberTarget) * 100).toFixed(
          1
        ),
        //get average pct between new sale value and new sale number
        saleResult: (
          (totalNewSalesValueActual / totalNewSalesValueTarget +
            totalNewSalesNumberActual / totalNewSalesNumberTarget) *
          50
        ).toFixed(1),
        stopStuActual: totalStopStuActual.toLocaleString(),
        nbrStu: totalNbrStu.toLocaleString(),
        stopStuPct: ((totalStopStuActual / totalNbrStu) * 100).toFixed(1),
      }
    },
  },
  methods: {
    downloadExcel() {
      if (this.filtered.length > 0) {
        const table_elt = document.getElementById('exported-kpi-table')
        const worksheet = XLSX.utils.table_to_sheet(table_elt)

        // Define the column width for specific columns
        const columnWidths = [
          { wch: 20 },
          { wch: 15 },
          { wch: 15 },
          { wch: 5 },
          { wch: 15 },
          { wch: 15 },
          { wch: 5 },
          { wch: 15 },
          { wch: 15 },
          { wch: 5 },
          { wch: 15 },
          { wch: 15 },
          { wch: 5 },
          { wch: 15 },
          { wch: 15 },
          { wch: 5 },
          { wch: 10 },
          { wch: 15 },
          { wch: 15 },
          { wch: 5 },
        ]
        worksheet['!cols'] = columnWidths

        const range = XLSX.utils.decode_range(worksheet['!ref'])
        // Loop through each cell in the range
        for (let row = range.s.r; row <= range.e.r; row++) {
          for (let col = range.s.c; col <= range.e.c; col++) {
            // Construct the cell address
            const cellAddress = XLSX.utils.encode_cell({ r: row, c: col })
            const cell = worksheet[cellAddress]
            const centerAlign = { vertical: 'center', horizontal: 'center' }
            if (cell) {
              //Center text
              cell.s = { alignment: { ...centerAlign } }

              if (this.headerText.includes(cell.v)) {
                //Set font to bold
                cell.s = { ...cell.s, ...{ font: { bold: true } } }
              } else if (cell.t === 'n' && cell.v > 1000) {
                // Apply number format with thousands separator
                cell.z = '#,##0'
              } else if (cell.v === 'Stopped Students' || cell.v === 'Total Students') {
                cell.s = { alignment: { ...centerAlign, ...{ wrapText: true } } }
              }
            }
          }
        }

        const workbook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(workbook, worksheet, 'KPI')

        XLSX.writeFile(
          workbook,
          'KPI report' + ' in ' + dayjs(this.date).format('DD/MM/YYYY') + '.xlsm'
        )
      }
    },
    async init() {
      const month = new Date(this.date).getMonth() + 1
      const year = new Date(this.date).getFullYear()
      this.isLoading = true
      await kpi_report
        .getKPIByMonth(month, year)
        .then(res => {
          this.listRender = res.map(el => {
            return {
              ...el,
              revenuePct: el.revenuePct * 100,
              newSalesValuePct: el.newSalesValuePct * 100,
              newSalesNumberPct: el.newSalesNumberPct * 100,
              avgStuPct: el.avgStuPct * 100,
              stopStuPct: el.stopStuPct * 100,
              numOfStuPct: el.numOfStuPct * 100,
              revenueActual: Math.floor(el.revenueActual),
              avgStuActual: Number(el.avgStuActual.toFixed(1)),
              numOfStuValue: Number(el.numOfStuValue.toFixed(1)) || 0,
            }
          })
          this.isLoading = false
        })
        .catch(() => {
          this.flashMessage.show({
            status: 'error',
            title: 'Error edit registered ',
            message: 'Please try again',
          })
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.v-data-table-header {
  border-radius: 30px;
}

thead th {
  text-align: center !important;
  padding: 0 !important;
  height: 44px;
}

.top > div {
  padding: 0;
  padding-top: 10px;
  padding-left: 10px;
}

tr td {
  text-align: center;
}

tr td:nth-of-type(1) {
  text-align: left;
}

.select-location {
  padding: 0;
  width: 150px;
  padding-left: 5px;
}

.v-data-table-header tr:nth-of-type(1) th:last-child {
  border-radius: 0 5px 0 0px;
}

.v-data-table-header tr:nth-of-type(2) th {
  height: 20px !important;
}

.v-data-table-header tr:nth-of-type(1) th:first-child {
  border-radius: 5px 0px 0 0px;
}

.link {
  padding-top: 0;
}

.link a {
  display: block;
  text-align: right;
}

.kpi-table-header th:nth-of-type(1) {
  z-index: 6 !important;
  left: 0;
}

.kpi-table tbody tr td:nth-of-type(1) {
  position: sticky;
  left: 0px;
  z-index: 4;
  background: #fff;
}

.kpi-table tbody tr:nth-of-type(even) td {
  background-color: #f3faff !important;
}
.kpi-table tbody tr:nth-of-type(even):hover td {
  background-color: #dbecf8 !important;
}
.kpi-table tbody tr:nth-of-type(odd):hover td {
  background-color: #d7dbdd !important;
}
.kpi-table tbody tr:nth-of-type(even) td {
  background-color: #f3faff !important;
}
.kpi-table tbody tr:nth-of-type(even) {
  background-color: #eeeeee !important;
}
</style>
